@tailwind base;
@tailwind components;
@tailwind utilities;
#manage_course_table div:nth-child(3) {
  overflow: visible !important;
}
body {
  margin: 0;
  font-family: "Poppins" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.list {
  border: 1px solid lightgray;

  list-style-type: none;
}

.details {
  color: #264bc4;
  font-size: 12px;
}
.primary-cta {
  @apply bg-orange text-white text-sm font-medium hover:bg-opacity-90 rounded-md py-2 px-8 justify-center focus:outline-none;
}
.secondary-cta {
  @apply bg-orange-light text-black p-2 rounded-md;
}
.tertiary-cta {
  @apply bg-gray-700 text-white p-2;
}
.accept-cta {
  @apply bg-green-500 px-4 py-2 text-white rounded-md;
}
.reject-cta {
  @apply bg-red-700 px-4 py-2 text-white rounded-md;
}

/* Tab Container */
.Tabs {
  width: 100%;
  height: auto;
  min-height: 400px;
  background: #fff;
  margin: 3.5rem auto 1.5rem;
  color: #e8f0f2;
  @media (max-width: 769px) {
    padding: 2rem 0;
  }
}

/* Tab Navigation */
ul.nav {
  width: 100%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: #d3d3d3;
  @media (max-width: 768px) {
    width: 90%;
  }
}
ul.nav li {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  /* border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem; */
  color: #000;
  font-weight: 500;
}
ul.nav li:nth-child(2) {
  border-radius: 0;
  /* border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem; */
}
ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
ul.nav li.active {
  background-color: #d3d3d3;
}

/* First and Second Tab Styles */
.FirstTab p,
.SecondTab p {
  font-size: 2rem;
  text-align: center;
}

.flex-row.text-center.owed {
  background-color: #d3d3d3;
  padding: 15px 0;
}
label.sc-jIRcFI.cqIunC.drop_area.drop_zone {
  width: 500px;
  height: 124px;
}
#manage_course_table .sc-ePzlA-D {
  z-index: 9999 !important;
  overflow-x: unset !important;
  overflow-y: visible !important;
}

.topping {
  margin-top: 0.3rem;
  vertical-align: text-bottom;
}

.result {
  margin-top: 1rem;
}

.toppings-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}

.toppings-list li {
  margin-bottom: 0.5rem;
}

.toppings-list-item {
  display: flex;
  justify-content: space-between;
}

.toppings-list li:first-child {
  border-top: 0px;
  margin-top: 1rem;
  padding-top: 1rem;
}

.toppings-list li {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  padding-top: 1rem;
}

.toppings-list-item label {
  vertical-align: text-bottom;
  margin-left: 0.2rem;
}

@media screen and (max-width: 600px) {
  .toppings-list {
    width: 90%;
  }
}
